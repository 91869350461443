import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { Table, Button } from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import "../Bec/bec.scss";
import { Link } from "react-router-dom";

function ListBe() {
  const baseUrl = `https://bqef-bo.com/api/P/cruB.php`;
  /////Listado usuarios becarios
  const [dataB, setDataB] = useState([]);

  useEffect(() => {
    const peticionGet = async () => {
      await axios
        .get(baseUrl)
        .then((response) => {
          //console.log(response.data);;
          setDataB(response.data);
          setTablaUsuarios(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    peticionGet();
  }, []);

  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    const regex = new RegExp(terminoBusqueda, "i");
    var resultadosBusqueda = tablaUsuarios.filter((elemento) => {
      return (
        regex.test(elemento.nombre) ||
        regex.test(elemento.apellido) ||
        regex.test(elemento.email) ||
        regex.test(elemento.carrera) ||
        regex.test(elemento.anio) ||
        regex.test(elemento.celular) ||
        regex.test(elemento.ciudad) ||
        regex.test(elemento.direccion)
      );
    });
    setDataB(resultadosBusqueda);
  };
  //////Filtrar columnas
  const [sortField, setSortField] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  const sortedData = dataB.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Listado Becarios</h2>
        </div>
        <div className="frm">
          <Link
            to={"https://bqef-bo.com/api/PDF/reporte_pdf_becarios.php"}
            target="_blank">
            <Button color="primary" size="lg">
              <FaIcons.FaFileDownload /> Reporte
            </Button>
          </Link>
        </div>
        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre, Apellido o Email"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
          <br />
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead id="stahead">
            <tr className="text-center">
              <th>N°</th>
              <th>
                <span onClick={() => handleSortingChange("nombre")}>
                  Nombres
                  {sortField === "nombre" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nombre" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("apellido")}>
                  Apellidos
                  {sortField === "apellido" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "apellido" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("email")}>
                  Correo
                  {sortField === "email" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "email" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("carrera")}>
                  Carrera
                  {sortField === "carrera" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "carrera" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("anio")}>
                  Año
                  {sortField === "anio" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "anio" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("celular")}>
                  Telf/Cel
                  {sortField === "celular" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "celular" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("ciudad")}>
                  Ciudad
                  {sortField === "ciudad" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "ciudad" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("direccion")}>
                  Dirección
                  {sortField === "direccion" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "direccion" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nom_pad")}>
                  Nombre Padre
                  {sortField === "nom_pad" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nom_pad" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nom_mad")}>
                  Nombre Madre
                  {sortField === "nom_mad" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nom_mad" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, nu3) => (
              <tr className="text-center" key={item.id}>
                <td>{nu3 + 1}</td>
                <td>{item.nombre}</td>
                <td>{item.apellido}</td>
                <td>{item.email}</td>
                <td>{item.carrera}</td>
                <td>{item.anio}</td>
                <td>{item.celular}</td>
                <td>{item.ciudad}</td>
                <td>{item.direccion}</td>
                <td>{item.nom_pad}</td>
                <td>{item.nom_mad}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ListBe;
