import React from "react";
import "./bec.scss";
import { Card, Button, CardText, Col } from "reactstrap";

function Becario() {
  return (
    <div className="d-flex justify-content-center">
      <Col id="colu">
        <Card
          body
          className="text-center"
          style={{
            background: "rgba(232, 232, 232, 0.65)",
          }}>
          <CardText>
            <h2>Bienvenido: </h2>
            <>
              <p>
                En caso de que haya sido aceptado para recibir y participar de
                la Beca en la institucion BQEF deberá completar el registro de
                sus datos.
              </p>
              <b>Por favor haga clic en el botón a continuación</b>
            </>
          </CardText>
          <Button id="but" href="/Becario/regcomp">
            Continuar registro
          </Button>
        </Card>
      </Col>
    </div>
  );
}

export default Becario;
