import React, { useState, useEffect } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import { Table, Button } from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import "../Bec/bec.scss";
import Swal from "sweetalert2";

function ListUserPat() {
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    id: "",
    nombre: "",
    id_pat: "",
    pais: "",
    email: "",
    direccion: "",
    celular: "",
    institucion: "",
  });
  // Definir un objeto de validación con patrones de expresiones regulares y estados de validación iniciales
  const validation = {
    nombre: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    id_pat: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    pais: { regex: /^[a-zA-Z\s]{1,10}$/, isValid: true },
    email: {
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      isValid: false,
    },
    direccion: {
      regex:
        /^[a-zA-Z0-9\s.,-ñÑáéíóúÁÉÍÓÚüÜ#°ºª!@#$%&*()_+=[\]{};':"\\|,.<>?]{1,40}$/,
      isValid: true,
    },
    celular: { regex: /^\d{8,8}$/, isValid: true },
    institucion: { regex: /^[a-zA-Z\s]{1,30}$/, isValid: true },
  };

  const [isValid, setIsValid] = useState({
    nombre: true,
    id_pat: true,
    pais: true,
    email: false,
    direccion: true,
    celular: true,
    institucion: true,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuarioSeleccionado((prevState) => ({ ...prevState, [name]: value }));

    const isValidValue = validation[name].regex.test(value);
    setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };
  ////Datos usuario Administrador
  const [dataAdm, setDataAdm] = useState({});
  const peticionGetAdm = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/contraadm.php`, {
        params: {
          id: localStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        //console.log(response.data);;
        setDataAdm(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////Listado usuarios patrocinador
  const baseUrl = "https://bqef-bo.com/api/A/cruPat.php";
  const UrlUpd = "https://bqef-bo.com/api/A/cruUPat.php";
  const [data, setData] = useState([]);
  const peticionGet = async () => {
    await axios
      .get(baseUrl)
      .then((response) => {
        setData(response.data);
        setTablaUsuarios(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////Crear Usuario patrocinador
  async function peticionPost(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("nombre", usuarioSeleccionado.nombre);
    fd.append("id_pat", usuarioSeleccionado.id_pat);
    fd.append("pais", usuarioSeleccionado.pais);
    fd.append("email", usuarioSeleccionado.email);
    fd.append("direccion", usuarioSeleccionado.direccion);
    fd.append("celular", usuarioSeleccionado.celular);
    fd.append("institucion", usuarioSeleccionado.institucion);
    fd.append("usu_creacion", dataAdm.id_adm);
    try {
      const res = await axios.post(baseUrl, fd);
      console.log(res.data);
      mostrarAlertaN();
      peticionGet();
      abrirCerrarModalInsertar();
    } catch (error) {
      console.log(error.response.data);
      mostrarAlertaError();
    }
  }
  /////Modificar Usuario patrocinador
  const peticionPut = async () => {
    var f = new FormData();
    f.append("nombre", usuarioSeleccionado.nombre);
    f.append("pais", usuarioSeleccionado.pais);
    f.append("email", usuarioSeleccionado.email);
    f.append("direccion", usuarioSeleccionado.direccion);
    f.append("celular", usuarioSeleccionado.celular);
    f.append("usu_modificacion", dataAdm.id_adm);
    f.append("institucion", usuarioSeleccionado.institucion);
    f.append("METHOD", "PUT");
    await axios
      .post(UrlUpd, f, { params: { id_pat: usuarioSeleccionado.id_pat } })
      .then((response) => {
        var dataNueva = data;
        dataNueva.map((Usuario) => {
          if (Usuario.id_pat === usuarioSeleccionado.id_pat) {
            Usuario.nombre = usuarioSeleccionado.nombre;
            Usuario.pais = usuarioSeleccionado.pais;
            Usuario.email = usuarioSeleccionado.email;
            Usuario.direccion = usuarioSeleccionado.direccion;
            Usuario.celular = usuarioSeleccionado.celular;
            Usuario.institucion = usuarioSeleccionado.institucion;
          }
        });
        setData(dataNueva);
        abrirCerrarModalEditar();
        mostrarAlertaU();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////Eliminar Usuario patrocinador
  async function peticionDelete(id_pat) {
    const res = await axios.delete(`${baseUrl}?id_pat=${id_pat}`);
    abrirCerrarModalEliminar();
    peticionGet();
    mostrarAlertaD();
    console.log(res.data);
  }

  const seleccionarUsuario = (Usuario, caso) => {
    setUsuarioSeleccionado(Usuario);

    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  useEffect(() => {
    peticionGet();
    peticionGetAdm();
  }, []);
  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);
  // const [usuarios, setUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    const lowerCaseTerm = terminoBusqueda.toLowerCase();
    const resultadosBusqueda = tablaUsuarios.filter((elemento) => {
      const { nombre, pais, id_pat, celular, institucion, email } = elemento;
      return (
        nombre.toLowerCase().includes(lowerCaseTerm) ||
        pais.toLowerCase().includes(lowerCaseTerm) ||
        id_pat.toString().toLowerCase().includes(lowerCaseTerm) ||
        celular.toString().toLowerCase().includes(lowerCaseTerm) ||
        institucion.toString().toLowerCase().includes(lowerCaseTerm) ||
        email.toString().toLowerCase().includes(lowerCaseTerm)
      );
    });
    setData(resultadosBusqueda);
  };
  ////ALERTAS
  const mostrarAlertaN = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Nuevo usuario registrado correctamente.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaU = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Usuario Modificado.",
      timer: 5000,
      icon: "info",
    });
  };
  const mostrarAlertaD = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Eliminado!",
      text: "Usuario Eliminado.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaError = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Error!",
      text: "No se pudo registrar.",
      timer: 5000,
      icon: "danger",
    });
  };
  //////Filtrar columnas
  const [sortField, setSortField] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  const sortedData = data.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Listado Patrocinadores</h2>
        </div>
        <div className="frm">
          <Button
            color="success"
            size="lg"
            onClick={() => abrirCerrarModalInsertar()}
          >
            <FaIcons.FaPlus /> Añadir
          </Button>
        </div>
        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre, Email o Institución"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead id="stahead">
            <tr className="text-center">
              <th>N°</th>
              <th>
                <span onClick={() => handleSortingChange("nombre")}>
                  Nombres
                  {sortField === "nombre" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nombre" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("id_pat")}>
                  ID Patrocinador
                  {sortField === "id_pat" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "id_pat" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("pais")}>
                  Pais
                  {sortField === "pais" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "pais" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("email")}>
                  Correo
                  {sortField === "email" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "email" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("direccion")}>
                  Direccion
                  {sortField === "direccion" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "direccion" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("celular")}>
                  Celular
                  {sortField === "celular" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "celular" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("institucion")}>
                  Institucion
                  {sortField === "institucion" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "institucion" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((Usuario, index) => (
              <tr className="text-center" key={Usuario.id}>
                <td>{index + 1}</td>
                <td>{Usuario.nombre}</td>
                <td>{Usuario.id_pat}</td>
                <td>{Usuario.pais}</td>
                <td>{Usuario.email}</td>
                <td>{Usuario.direccion}</td>
                <td>{Usuario.celular}</td>
                <td>{Usuario.institucion}</td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => seleccionarUsuario(Usuario, "Editar")}
                  >
                    Editar
                  </button>{" "}
                  {"  "}
                  <button
                    className="btn btn-danger"
                    onClick={() => seleccionarUsuario(Usuario, "Eliminar")}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Modal añadir usuario */}
        <Modal isOpen={modalInsertar}>
          <ModalHeader className="header_mo">Insertar Patrocinador</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Nombres: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="nombre"
                  value={usuarioSeleccionado.nombre}
                  onChange={handleChange}
                />
                {!isValid.nombre && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Id Usuario: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="id_pat"
                  value={usuarioSeleccionado.id_pat}
                  onChange={handleChange}
                />
                {!isValid.id_pat && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Pais: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="pais"
                  value={usuarioSeleccionado.pais}
                  onChange={handleChange}
                />
                {!isValid.pais && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 10 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Correo: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={usuarioSeleccionado.email}
                  onChange={handleChange}
                />
                {!isValid.email && (
                  <span style={{ color: "red" }}>Correo no válido</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Direccion: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="direccion"
                  value={usuarioSeleccionado.direccion}
                  onChange={handleChange}
                />
                {!isValid.direccion && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 40 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Celular: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="celular"
                  value={usuarioSeleccionado.celular}
                  onChange={handleChange}
                />
                {!isValid.celular && (
                  <span style={{ color: "red" }}>Número celular no válido</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Institucion: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="institucion"
                  value={usuarioSeleccionado.institucion}
                  onChange={handleChange}
                />
                {!isValid.institucion && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 30 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={(e) => peticionPost(e)}
              disabled={
                !isValid.nombre ||
                !isValid.id_pat ||
                !isValid.pais ||
                !isValid.email ||
                !isValid.direccion ||
                !isValid.celular ||
                !isValid.institucion
              }
            >
              Guardar
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalInsertar()}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modal Editar */}
        <Modal isOpen={modalEditar}>
          <ModalHeader className="header_mo">Editar Usuario</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Nombres: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="nombre"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.nombre}
                />
                {!isValid.nombre && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>pais: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="pais"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.pais}
                />
                {!isValid.pais && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 10 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Correo: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.email}
                />
                {!isValid.email && (
                  <span style={{ color: "red" }}>Correo no válido</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Direccion: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="direccion"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.direccion}
                />
                {!isValid.direccion && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 40 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Celular: </Label>
                <br />
                <Input
                  type="text"
                  className="form-control"
                  name="celular"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.celular}
                />
                {!isValid.celular && (
                  <span style={{ color: "red" }}>Número celular no válido</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Institucion: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="institucion"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.institucion}
                />
                {!isValid.institucion && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 30 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" size="lg" onClick={() => peticionPut()}>
              Modificar
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEditar()}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEliminar}>
          <ModalHeader className="header_mo">Eliminar comunicado</ModalHeader>
          <ModalBody>
            ¿Estás seguro que deseas eliminar el Usuario{" "}
            {usuarioSeleccionado && usuarioSeleccionado.nombre}?
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={() => peticionDelete(usuarioSeleccionado.id_pat)}
            >
              Sí
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEliminar()}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default ListUserPat;
