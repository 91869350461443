import React, { useState, useEffect } from "react";
import { FormGroup, Input, Row, Form, Col, Label, Button } from "reactstrap";
import "./bec.scss";
import axios from "axios";
import Swal from "sweetalert2";

function RegComp() {
  const [data, setData] = useState({
    id: "",
    nombre: "",
    apellido: "",
    email: "",
    id_bec: "",
    carrera: "",
    celular: "",
    institucion: "",
    anio: "",
    ciudad: "",
    direccion: "",
    nom_pad: "",
    nom_mad: "",
  });
  // Definir un objeto de validación con patrones de expresiones regulares y estados de validación iniciales
  const validation = {
    nombre: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,20}$/,
      isValid: true,
    },
    apellido: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,20}$/,
      isValid: true,
    },
    institucion: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,30}$/,
      isValid: true,
    },
    carrera: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,30}$/,
      isValid: true,
    },
    email: {
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      isValid: false,
    },
    ciudad: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,20}$/,
      isValid: true,
    },
    direccion: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,40}$/,
      isValid: true,
    },
    nom_pad: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,30}$/,
      isValid: true,
    },
    nom_mad: {
      regex: /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]{1,30}$/,
      isValid: true,
    },
    celular: { regex: /^\d{8,12}$/, isValid: true },
    anio: { regex: /^\d{4,4}$/, isValid: true },
  };

  const [isValid, setIsValid] = useState({
    nombre: true,
    apellido: true,
    institucion: true,
    carrera: true,
    email: true,
    ciudad: true,
    direccion: true,
    nom_pad: true,
    nom_mad: true,
    celular: true,
    anio: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));

    const isValidValue = validation[name].regex.test(value);
    setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
  };
  ///Mostrar Datos Becario
  const peticionGet = async () => {
    await axios
      .get(`https://bqef-bo.com/api/B/regB.php`, {
        params: {
          id: localStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        // // ////console.log(response.data);;
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const peticionPut = async () => {
    var f = new FormData();
    f.append("nombre", data.nombre);
    f.append("apellido", data.apellido);
    f.append("email", data.email);
    f.append("carrera", data.carrera);
    f.append("celular", data.celular);
    f.append("institucion", data.institucion);
    f.append("anio", data.anio);
    f.append("ciudad", data.ciudad);
    f.append("direccion", data.direccion);
    f.append("nom_pad", data.nom_pad);
    f.append("nom_mad", data.nom_mad);
    f.append("METHOD", "PUT");
    await axios
      .post(`https://bqef-bo.com/api/B/regB.php`, f, {
        params: { id: data.id, idB: localStorage.getItem("iduser") },
      })
      .then((response) => {
        setData(response.data);
        // // ////console.log(response.data);;
        mostrarAlertaU();
        peticionGet();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    peticionGet();
  }, []);
  ////ALERTAS
  const mostrarAlertaU = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Registro Actualizado.",
      timer: 5000,
      icon: "success",
    });
  };
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Registro Becario</h2>
        </div>
        <Form className="frm">
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Nombres:</Label>
                <Input
                  name="nombre"
                  type="text"
                  onChange={handleChange}
                  value={data.nombre}
                />
                {!isValid.nombre && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Apellidos:</Label>
                <Input
                  name="apellido"
                  type="text"
                  onChange={handleChange}
                  value={data.apellido}
                />
                {!isValid.apellido && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>ID Usuario:</Label>
                <Input
                  disabled
                  name="id_bec"
                  type="text"
                  // onChange={handleChange}
                  value={data.id_bec}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Universidad/Institución:</Label>
                <Input
                  name="institucion"
                  type="text"
                  onChange={handleChange}
                  value={data.institucion}
                />
                {!isValid.institucion && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 30 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Carrera:</Label>
                <Input
                  name="carrera"
                  type="text"
                  onChange={handleChange}
                  value={data.carrera}
                />
                {!isValid.carrera && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 30 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Correo:</Label>
                <Input
                  name="email"
                  type="text"
                  onChange={handleChange}
                  value={data.email}
                />
                {!isValid.email && (
                  <span style={{ color: "red" }}>Correo no válido</span>
                )}
              </FormGroup>
            </Col>

            <Col md={2}>
              <FormGroup>
                <Label>Celular:</Label>
                <Input
                  name="celular"
                  type="text"
                  onChange={handleChange}
                  value={data.celular}
                />
                {!isValid.celular && (
                  <span style={{ color: "red" }}>Número celular no válido</span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Ciudad:</Label>
                <Input
                  name="ciudad"
                  type="text"
                  onChange={handleChange}
                  value={data.ciudad}
                />
                {!isValid.ciudad && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={7}>
              <FormGroup>
                <Label>Dirección:</Label>
                <Input
                  name="direccion"
                  type="text"
                  onChange={handleChange}
                  value={data.direccion}
                />
                {!isValid.direccion && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 40 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label>Año Inicio:</Label>
                <Input
                  name="anio"
                  type="text"
                  onChange={handleChange}
                  value={data.anio}
                />
                {!isValid.anio && (
                  <span style={{ color: "red" }}>Formato año no válido</span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Nombre Padre:</Label>
                <Input
                  name="nom_pad"
                  type="text"
                  onChange={handleChange}
                  value={data.nom_pad}
                />
                {!isValid.nom_pad && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 30 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Nombre Madre:</Label>
                <Input
                  name="nom_mad"
                  type="text"
                  onChange={handleChange}
                  value={data.nom_mad}
                />
                {!isValid.nom_mad && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 30 letras y espacios
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Button
            className="btn btn-success"
            size="lg"
            onClick={() => peticionPut()}
            disabled={
              !isValid.nombre ||
              !isValid.apellido ||
              !isValid.email ||
              !isValid.institucion ||
              !isValid.carrera ||
              !isValid.celular ||
              !isValid.ciudad ||
              !isValid.direccion ||
              !isValid.anio ||
              !isValid.nom_pad ||
              !isValid.nom_mad
            }
          >
            Modificar
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default RegComp;
