import React, { useState, useEffect } from "react";
import "../navbar.scss";
import Logo from "../img/base/111.png";
import axios from "axios";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Label,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { Outlet, useNavigate } from "react-router-dom";
import SidebarPer from "./SidebarPer";
import Swal from "sweetalert2";

function NavbarBec(args) {
  ////
  const [modalContra, setModalContra] = useState(false);
  const abrirCerrarModalContra = () => {
    setModalContra(!modalContra);
  };
  ////FECHA
  const [modalFecha, setModalFecha] = useState(false);
  const abrirCerrarModalFecha = () => {
    setModalFecha(!modalFecha);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataPer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(dataPer);
  };
  ////Mostrar fecha
  const [usuarioSeleccionado, setusuarioSeleccionado] = useState({
    id_fech: "",
    fecha: "",
    fech_lit: "",
  });
  const [fechaError, setFechaError] = useState(false);

  const handleChangefecha = (e) => {
    const { name, value } = e.target;
    setusuarioSeleccionado((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value === "") {
      setFechaError(true);
    } else {
      setFechaError(false);
    }
  };
  /////Listar datos personal administrativo
  const [dataPer, setDataPer] = useState({});
  const peticionGetContraseña = async () => {
    await axios
      .get(`https://bqef-bo.com/api/P/contraper.php`, {
        params: {
          id: localStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        //console.log(response.data);;
        setDataPer(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", mostrarAlertaCie(), 5000);
    naviget("/");
  }
  // const user = localStorage.getItem("user");

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  ////Modificar contraseña Personal Administrativo
  const peticionPutContra = async () => {
    var f = new FormData();
    f.append("contrasena", dataPer.contrasena);
    f.append("contrasena_lit", dataPer.contrasena_lit);
    f.append("METHOD", "PUT");
    await axios
      .post(`https://bqef-bo.com/api/P/contraper.php`, f, {
        params: { idb: dataPer.id },
      })
      .then((response) => {
        setDataPer(response);
        peticionGetContraseña();
        mostrarAlertaU();
        abrirCerrarModalContra();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Mostrar fecha
  const [listaF, setListaF] = useState([]);
  const peticionGetFecha = async () => {
    await axios
      .get(`https://bqef-bo.com/api/P/fecIP.php`)
      .then((response) => {
        //console.log(response.data);;
        setusuarioSeleccionado(response.data);
        setListaF(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Modificar Fecha
  async function addFecha(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("fecha", usuarioSeleccionado.fecha);
    const res = await axios.post("https://bqef-bo.com/api/P/fecIP.php", fd);
    console.log(res.data);
    mostrarAlertaFec();
    abrirCerrarModalFecha();
    peticionGetFecha();
  }
  ////
  useEffect(() => {
    peticionGetFecha();
    peticionGetContraseña();
  }, []);

  ////Mostrar comunicados
  const [lista, setLista] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    getComunicado();
  }, []);
  async function getComunicado() {
    const res = await axios.get("https://bqef-bo.com/api/A/comCA.php");
    setLista(res.data);
    // console.log(res.data);
  }

  ////Descargar PDF
  const handleDescargarPdf = async () => {
    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:application/pdf;base64," + selectedItem.archivo_com
    );
    link.setAttribute("download", selectedItem.nom_doc);
    link.click();
  };

  ////ALERTAS
  const mostrarAlertaU = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Contraseña Actualizada.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaFec = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Fecha Actualizada.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaCie = () => {
    Swal.fire({
      showConfirmButton: false,
      text: "Cerrando sesión!",
      timer: 5000,
      icon: "warning",
    });
  };
  ////
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };
  ////Boton Cerrar
  const closeBtn = () => {
    setSelectedItem(null);
  };
  const cloBtn = (
    <Button className="close" onClick={closeBtn}>
      &times;
    </Button>
  );
  return (
    <div>
      <Navbar expand="md" {...args}>
        <SidebarPer />
        <NavbarBrand href="Personal" className="text-light">
          <img
            src={Logo}
            alt="logo"
            className="me-2"
            style={{
              height: 60,
              width: 120,
            }}
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            {/* //REVISAR */}
            <NavbarText className="text-light">
              {dataPer.nombre}
              {"   "}
              {dataPer.apellido}
            </NavbarText>
            <UncontrolledDropdown nav direction="down">
              <DropdownToggle nav caret className="text-light">
                MENU
              </DropdownToggle>
              <DropdownMenu className="drop-menu">
                <DropdownItem href="./pdfman" target="_blank">
                  Instrucciones
                </DropdownItem>
                {lista.map((item) => (
                  <DropdownItem
                    key={item.id_com}
                    onClick={() => handleSelectItem(item)}>
                    Postulacion
                  </DropdownItem>
                ))}
                <DropdownItem onClick={() => abrirCerrarModalFecha()}>
                  Fecha Entrega
                </DropdownItem>
                <DropdownItem href="http://www.bqef.org/" target="_blank">
                  Sobre Nosotros...
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => abrirCerrarModalContra()}>
                  Cambiar Contraseña
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logoutSubmit}>Salir</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>

      {/* MODAL CONTRASEÑA */}
      <Modal isOpen={modalContra}>
        <ModalHeader className="header_mo">Editar Contraseña</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label>Clave Actual: </label>
            <br />
            <input
              disabled
              type="text"
              className="form-control"
              name="contrasena_lit"
              onChange={handleChange}
              value={dataPer.contrasena_lit}
            />
            <br />
            <label>Nueva Clave: </label>
            <br />
            <input
              type="text"
              className="form-control"
              name="contrasena"
              onChange={handleChange}
              value={dataPer.contrasena}
            />
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" size="lg" onClick={() => peticionPutContra()}>
            Guardar
          </Button>
          {"   "}
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalContra()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* MODAL FECHA */}
      <Modal isOpen={modalFecha}>
        <ModalHeader className="header_mo">
          Establecer Fecha Entrega
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <Label>Fecha Establecida: </Label>
            {listaF.map((item) => (
              <h2 className="text-center" key={item.id_fech}>
                "{item.fech_lit}"
              </h2>
            ))}
            <Label>Nueva Fecha: </Label>
            <br />
            <Input
              type="date"
              className="form-control"
              name="fecha"
              value={usuarioSeleccionado.fecha}
              onChange={handleChangefecha}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            size="lg"
            onClick={addFecha}
            disabled={fechaError || usuarioSeleccionado.fecha === ""}>
            Guardar
          </Button>
          {fechaError && (
            <span style={{ color: "red" }}>
              Please select a valid date and time before saving
            </span>
          )}
          {"   "}
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalFecha()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal VER */}
      <Modal isOpen={selectedItem !== null} size="xl">
        {selectedItem && (
          <>
            <ModalHeader className="header_m" close={cloBtn}>
              {selectedItem.nom_doc}
            </ModalHeader>
            <ModalBody>
              <Card>
                <CardBody className="p-0">
                  <Row className="justify-content-center">
                    <object
                      data={
                        "data:application/pdf;base64," +
                        selectedItem.archivo_com
                      }
                      type="application/pdf"
                      alt="archivo_per"
                      width="400"
                      height="600">
                      <p>
                        Tu navegador no puede mostrar este archivo PDF. Puedes
                        descargarlo
                        <a
                          href={
                            "data:application/pdf;base64," +
                            selectedItem.archivo_com
                          }
                          download>
                          aquí
                        </a>
                        .
                      </p>
                    </object>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{ float: "right" }}
                color="success"
                size="lg"
                onClick={handleDescargarPdf}>
                <FaIcons.FaDownload />
                Download
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      <Outlet />
    </div>
  );
}

export default NavbarBec;
