import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Table, Button } from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import "../Bec/bec.scss";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function Evalua() {
  const baseUrl = `https://bqef-bo.com/api/P/becE.php`;
  const [modalEditar, setModalEditar] = useState(false);
  const [usuarioSeleccionado, setusuarioSeleccionado] = useState({
    id: "",
    nombre: "",
    apellido: "",
    email: "",
    contrasena: "",
    celular: "",
    nota_eva: "",
  });
  ////1
  const [fram1, setFram1] = useState("");
  const [isValid, setIsValid] = useState({
    p1: false,
    p2: false,
    p3: false,
    p4: false,
  });

  const cambioRadioFram1 = (e) => {
    const { value } = e.target;
    setFram1(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p1: isValidValue }));
  };
  ///2
  const [fram2, setFram2] = useState();

  const cambioRadioFram2 = (e) => {
    const { value } = e.target;
    setFram2(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p2: isValidValue }));
  };
  ///3
  const [fram3, setFram3] = useState();

  const cambioRadioFram3 = (e) => {
    const { value } = e.target;
    setFram3(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p3: isValidValue }));
  };
  ///4
  const [fram4, setFram4] = useState();

  const cambioRadioFram4 = (e) => {
    const { value } = e.target;
    setFram4(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p4: isValidValue }));
  };
  ////
  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };
  ////Listar datos becarios
  const [dataBE, setDataBE] = useState([]);

  const peticionGet = async () => {
    await axios
      .get(baseUrl)
      .then((response) => {
        //console.log(response.data);;
        setDataBE(response.data);
        setTablaUsuarios(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Evaluacion becarios
  const peticionPut = async () => {
    var f = new FormData();
    f.append("p1", fram1);
    f.append("p2", fram2);
    f.append("p3", fram3);
    f.append("p4", fram4);
    f.append("METHOD", "POST");
    await axios
      .post("https://bqef-bo.com/api/P/evaB.php", f, {
        params: { id: usuarioSeleccionado.id_bec },
      })
      .then((response) => {
        setDataBE(dataBE.concat(response.data));
        //console.log(response.data);;
        peticionGet();
        mostrarAlertaEv();
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////
  const seleccionarUsuario = (Usuario) => {
    setusuarioSeleccionado(Usuario);
    abrirCerrarModalEditar();
  };
  useEffect(() => {
    peticionGet();
  }, []);
  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);
  // const [usuarios, setUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaUsuarios.filter(
      (elemento) =>
        elemento.nombre
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.apellido
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.email
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.celular
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.nota_eva
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase())
    );
    setDataBE(resultadosBusqueda);
  };
  ////ALERTAS
  const mostrarAlertaEv = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Registro Guardado.",
      timer: 5000,
      icon: "success",
    });
  };
  //////Filtrar columnas
  const [sortField, setSortField] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  const sortedData = dataBE.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Listado Becarios</h2>
        </div>
        <div className="frm">
          <Link
            to={"https://bqef-bo.com/api/PDF/reporte_pdf_Evaluacion.php"}
            target="_blank">
            <Button color="primary" size="lg">
              <FaIcons.FaFileDownload /> Reporte
            </Button>
          </Link>
        </div>
        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre, Apellido o Email"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
          <br />
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead id="stahead">
            <tr className="text-center">
              <th>N°</th>
              <th>
                <span onClick={() => handleSortingChange("nombre")}>
                  Nombre Usuario
                  {sortField === "nombre" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nombre" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("email")}>
                  Correo
                  {sortField === "email" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "email" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("celular")}>
                  Telf/Cel
                  {sortField === "celular" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "celular" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>Acción</th>
              <th>
                <span onClick={() => handleSortingChange("nota_eva")}>
                  Evaluación P.
                  {sortField === "nota_eva" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nota_eva" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nota_eva")}>
                  Nota Final
                  {sortField === "nota_eva" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nota_eva" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, num2) => (
              <tr className="text-center" key={item.id}>
                <td>{num2 + 1}</td>
                <td>
                  {item.nombre} {item.apellido}
                </td>
                <td>{item.email}</td>
                <td>{item.celular}</td>
                <td>
                  <Button
                    className="btn btn-secondary"
                    onClick={() => seleccionarUsuario(item)}>
                    <FaIcons.FaClipboardCheck />
                    &nbsp;EVALUAR
                  </Button>
                </td>
                {/* <td>{item.not_sist}</td> */}
                <td>{item.nota_eva}</td>
                <td>
                  <strong>{item.nota_eva}</strong>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/*MODAL EVALUAR */}
        <Modal isOpen={modalEditar}>
          <ModalHeader className="header_mo">Evaluar Becario</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>ID Becario: </label>
              <br />
              <input
                disabled
                type="text"
                className="form-control"
                name="nombre"
                value={usuarioSeleccionado && usuarioSeleccionado.id_bec}
              />
              <br />
              <div>
                <Container>
                  <Row>
                    <h5>1. Asistencia a las reuniones</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio1"
                          type="radio"
                          name="p1"
                          value="25"
                          checked={fram1 === "25"}
                          onChange={cambioRadioFram1}
                        />
                        <Label for="radio1">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio2"
                          type="radio"
                          name="p1"
                          value="13"
                          checked={fram1 === "13"}
                          onChange={cambioRadioFram1}
                        />
                        <Label for="radio2">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio3"
                          type="radio"
                          name="p1"
                          value="1"
                          checked={fram1 === "1"}
                          onChange={cambioRadioFram1}
                        />
                        <Label for="radio3">Malo</Label>
                      </FormGroup>
                      {isValid.p1 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <h5>2. Puntualidad</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio4"
                          type="radio"
                          name="p2"
                          value="25"
                          checked={fram2 === "25"}
                          onChange={cambioRadioFram2}
                        />
                        <Label for="radio4">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio5"
                          type="radio"
                          name="p2"
                          value="13"
                          checked={fram2 === "13"}
                          onChange={cambioRadioFram2}
                        />
                        <Label for="radio5">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio6"
                          type="radio"
                          name="p2"
                          value="1"
                          checked={fram2 === "1"}
                          onChange={cambioRadioFram2}
                        />
                        <Label for="radio6">Malo</Label>
                      </FormGroup>
                      {isValid.p2 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <h5>3. Compañerismo</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio7"
                          type="radio"
                          name="p3"
                          value="25"
                          checked={fram3 === "25"}
                          onChange={cambioRadioFram3}
                        />
                        <Label for="radio7">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio8"
                          type="radio"
                          name="p3"
                          value="13"
                          checked={fram3 === "13"}
                          onChange={cambioRadioFram3}
                        />
                        <Label for="radio8">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio9"
                          type="radio"
                          name="p3"
                          value="1"
                          checked={fram3 === "1"}
                          onChange={cambioRadioFram3}
                        />
                        <Label for="radio9">Malo</Label>
                      </FormGroup>
                      {isValid.p3 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <h5>4. Cumplimiento reglamento institucional</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio10"
                          type="radio"
                          name="p4"
                          value="25"
                          checked={fram4 === "25"}
                          onChange={cambioRadioFram4}
                        />
                        <Label for="radio10">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio11"
                          type="radio"
                          name="p4"
                          value="13"
                          checked={fram4 === "13"}
                          onChange={cambioRadioFram4}
                        />
                        <Label for="radio11">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio12"
                          type="radio"
                          name="p4"
                          value="1"
                          checked={fram4 === "1"}
                          onChange={cambioRadioFram4}
                        />
                        <Label for="radio12">Malo</Label>
                      </FormGroup>
                      {isValid.p4 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={peticionPut}
              disabled={!fram1 || !fram2 || !fram3 || !fram4}>
              EVALUAR
            </Button>
            {"   "}
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEditar()}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Evalua;
