import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Style.scss";
import * as FaIcons from "react-icons/fa";
import {
  Button,
  InputGroup,
  InputGroupText,
  Input,
  Form,
  Col,
  Row,
  Container,
} from "reactstrap";
import Swal from "sweetalert2";

function LoginBec() {
  const navigate = useNavigate();
  const [iduser, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/");
    }
    let loginStatus = localStorage.getItem("loginStatus");
    if (loginStatus) {
      setError(loginStatus);
      setTimeout(function () {
        localStorage.clear();
        window.location.reload();
      }, 400);
    }
    setTimeout(function () {
      setMsg("");
    }, 700);
  }, [msg, navigate]);

  const handleInputChange = (e, type) => {
    switch (type) {
      case "iduser":
        setError("");
        setUser(e.target.value);
        if (e.target.value === "") {
          setError("Nombre de usuario vacío");
        }
        break;
      case "pass":
        setError("");
        setPass(e.target.value);
        if (e.target.value === "") {
          setError("Contraseña esta vacía");
        }
        break;
      case "userType":
        setError("");
        setUserType(e.target.value);
        break;
      default:
    }
  };

  function loginSubmit() {
    if (iduser === "" || pass === "" || userType === "") {
      // setError("Llenar todos los campos!");
      mostrarAlertaE();
      return;
    }

    const url = `https://bqef-bo.com/api/login${userType}.php`;
    const headers = {
      Accept: "application/json",
      "Content-type": "application/json",
    };
    const Data = {
      iduser: iduser,
      pass: pass,
    };

    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(Data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (
          response[0].result === "Usuario no válido!" ||
          response[0].result === "Contraseña no válida!"
        ) {
          setError(response[0].result);
        } else {
          setMsg(response[0].result);
          mostrarAlertaS();
          setTimeout(function () {
            localStorage.setItem("login", true);
            localStorage.setItem("iduser", iduser);
            localStorage.setItem("userType", userType);
            navigate(`/${userType}/${userType}`);
          }, 2000);
        }
      })
      .catch((err) => {
        setError(err);
        console.log(err);
      });
  }
  ////ALERTAS
  const mostrarAlertaS = () => {
    let timerInterval;
    Swal.fire({
      title: "Éxito!",
      html: "Iniciando Sesión en <b></b> milisegundos.",
      timer: 2000,
      icon: "success",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
  };
  const mostrarAlertaE = () => {
    Swal.fire({
      confirmButtonColor: "#f1a460",
      title: "Ooops...!",
      text: "Debe llenar los campos.",
      timer: 5000,
      icon: "warning",
    });
  };
  return (
    <Container className="text-center">
      <Row>
        <Col>
          <div id="form">
            <h1 className="im">
              <FaIcons.FaUserCircle />
            </h1>
            <h2>INICIAR SESIÓN</h2>
            <br />
            <p>
              {error !== "" ? (
                <span className="error">{error}</span>
              ) : (
                <span className="success">{msg}</span>
              )}
            </p>
            <InputGroup>
              <InputGroupText>
                <FaIcons.FaUserAlt style={{ color: "rgb(17, 119, 167)" }} />
              </InputGroupText>
              <Input
                className="form-control"
                type="text"
                placeholder="Usuario"
                value={iduser}
                onChange={(e) => handleInputChange(e, "iduser")}
              />
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupText>
                <FaIcons.FaLock style={{ color: "rgb(17, 119, 167)" }} />
              </InputGroupText>
              <Input
                className="form-control"
                type="password"
                placeholder="Contraseña"
                value={pass}
                onChange={(e) => handleInputChange(e, "pass")}
              />
            </InputGroup>
            <br />
            <Form>
              <InputGroup>
                <InputGroupText>
                  <FaIcons.FaUserTag style={{ color: "rgb(17, 119, 167)" }} />
                </InputGroupText>
                <select
                  className="form-select"
                  name="userType"
                  value={userType}
                  onChange={(e) => handleInputChange(e, "userType")}
                >
                  <option value="">Seleccione el tipo de usuario</option>
                  <option value="Becario">Becario</option>
                  <option value="Personal">Personal</option>
                  <option value="Administrador">Administrador</option>
                </select>
              </InputGroup>
            </Form>
            <br />
            <Button className="bt" onClick={loginSubmit}>
              INGRESAR
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginBec;
