import React, { useState, useEffect } from "react";
import "../Bec/bec.scss";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  CardGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Input,
  Alert,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import axios from "axios";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import { color } from "chart.js/helpers";

const Administrador = () => {
  ////GET BECARIOS AÑOS
  const [anio, setAnio] = useState([]);
  const [anioB, setAnioB] = useState([]);
  const [incrementos, setIncrementos] = useState([]);
  const [estimado, setEstimado] = useState([]);
  ///Listar años becarios
  const peticionGet1bec = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/anioAB.php`)
      .then((response) => {
        var respuesta = response.data;
        var auxAnio = [],
          auxAnioB = [];
        respuesta.map((elemento) => {
          auxAnio.push(elemento.anio);
          auxAnioB.push(elemento.Incritos);
        });
        setAnio(auxAnio);
        setAnioB(auxAnioB);
        const incrementos = calcularIncremento(auxAnioB);
        setIncrementos(incrementos);
        const estimado = calcularEstimado(auxAnioB, incrementos);
        setEstimado(estimado); // Actualizar el estado con el estimado poblacional
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calcularIncremento = (anioB) => {
    const incrementos = [];
    for (let i = 1; i < anioB.length; i++) {
      const incremento = ((anioB[i] - anioB[i - 1]) / anioB[i - 1]) * 100;
      incrementos.push(incremento);
    }
    return incrementos;
  };

  const calcularEstimado = (anioB, incrementos) => {
    const estimado = [];
    const promedioIncremento =
      incrementos.reduce((a, b) => a + b, 0) / incrementos.length;
    let ultimoValor = anioB[anioB.length - 1];
    for (let i = 0; i < 30; i++) {
      // Predecir los próximos 5 años
      const estimadoValor = ultimoValor * (1 + promedioIncremento / 100);
      estimado.push(estimadoValor);
      ultimoValor = estimadoValor; // Actualizar el último valor para la próxima iteración
    }
    return estimado;
  };

  ////Listar region becarios
  const [ciudad, setCiudad] = useState([]);
  const [region, setRegion] = useState([]);

  const peticionGetbecC = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/ciuCB.php`)
      .then((response) => {
        //console.log(response.data);;
        var respuesta = response.data;
        var auxCiu = [],
          auxReg = [];
        respuesta.map((elemento) => {
          auxCiu.push(elemento.ciudad);
          auxReg.push(elemento.region);
        });
        setCiudad(auxCiu);
        setRegion(auxReg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Listar becarios
  const [dataB, setDataB] = useState([]);

  const peticionGetB = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/dashBE.php`)
      .then((response) => {
        //console.log(response.data);;
        setDataB(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////GET PERSONAL
  const [dataP, setDataP] = useState([]);
  const peticionGetP = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/dashPER.php`)
      .then((response) => {
        //console.log(response.data);;
        setDataP(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [dataF, setDataF] = useState([]);
  const [dataFI, setDataFI] = useState([]);
  const [dataL, setDataL] = useState([]);

  ///Mostrar fecha cargado informe
  useEffect(() => {
    const peticionGetFI = async () => {
      try {
        const response = await axios.get(`https://bqef-bo.com/api/A/fecEI.php`);
        //console.log(response.data);; // Imprime la respuesta en la consola
        setDataFI(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    ////Mostrar Fecha Entrega Informe
    const peticionGetF = async () => {
      try {
        const response = await axios.get(`https://bqef-bo.com/api/A/fecEP.php`);
        //console.log(response.data);; // Imprime la respuesta en la consola
        setDataF(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    ////Listar Logs de eventos
    async function peticionGetLog() {
      const res = await axios.get(`https://bqef-bo.com/api/A/logLM.php`);
      setDataL(res.data);
      // console.log(res.data);
    }
    peticionGetFI();
    peticionGetF();
    peticionGetLog();
  }, []);

  ////Listar informes entregados
  const [dataI, setDataI] = useState([]);
  const peticionGetI = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/dashINF.php`)
      .then((response) => {
        //console.log(response.data);;
        setDataI(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    peticionGet1bec();
    peticionGetbecC();
    peticionGetB();
    peticionGetP();
    peticionGetI();
  }, []);
  ////GRAFICO BARRAS
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Tooltip,
    Legend
  );
  const data = {
    labels: anio,
    datasets: [
      {
        label: "Inscritos",
        backgroundColor: "rgba(49, 51, 124, 0.7)",
        borderColor: "rgb(49, 51, 124)",
        borderWidth: 3,
        hoverBackgroundColor: "rgba(49, 51, 124, 0.8)",
        hoverBorderColor: "rgba(49, 51, 124)",
        data: anioB,
      },
    ],
  };
  const opciones = {
    maintainAspectRatio: false,
    responsive: true,
    ticks: {
      font: {
        size: 15,
        weight: "bold",
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 15,
            weight: "bold",
          },
        },
      },
    },
  };
  /////GRAFICO PIE
  const dataPie = {
    labels: ciudad,
    datasets: [
      {
        label: "# miembros",
        data: region,
        backgroundColor: [
          "rgba(217, 83, 79, 0.6)",
          "rgba(10, 49, 67, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
        borderColor: [
          "rgba(217, 83, 79, 0.8)",
          "rgba(10, 49, 67, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        hoverBackgroundColor: [
          "rgba(217, 83, 79, 0.9)",
          "rgba(10, 49, 67, 0.9)",
          "rgba(255, 206, 86, 0.9)",
          "rgba(75, 192, 192, 0.9)",
          "rgba(153, 102, 255, 0.9)",
          "rgba(255, 159, 64, 0.9)",
        ],
        hoverBorderColor: [
          "rgba(217, 83, 79)",
          "rgba(10, 49, 67)",
          "rgba(255, 206, 86)",
          "rgba(75, 192, 192)",
          "rgba(153, 102, 255)",
          "rgba(255, 159, 64)",
        ],
        borderWidth: 3,
      },
    ],
  };
  const opcionesPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 15,
            weight: "bold",
          },
        },
      },
    },
  };
  /////dataFI
  const [modalVer, setModalVer] = useState(false);
  const abrirCerrarModalVer = () => {
    setModalVer(!modalVer);
  };
  ////
  const closeBtn = (
    <Button className="close" onClick={() => abrirCerrarModalVer()}>
      &times;
    </Button>
  );
  ////Modal REPORTE INFORMES
  const [modalVerInf, setModalVerInf] = useState(false);
  const abrirCerrarModalVerInf = () => {
    setModalVerInf(!modalVerInf);
  };

  ////Modal Proyeccion
  const [modalProy, setModalProy] = useState(false);
  const abrirCerrarModalProy = () => {
    setModalProy(!modalProy);
  };
  /////proyección
  ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement);
  const datosGrafico = {
    labels: anio,
    datasets: [
      {
        label: "Registros actuales",
        data: anioB,
        borderColor: "rgb(49, 51, 124, 0.5)",
        backgroundColor: "rgb(49, 51, 124, 0.5)",
        pointBackgroundColor: "rgb(49, 51, 124, 0.8)",
        pointBorderColor: "rgb(49, 51, 124, 0.5)",
        pointBorderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgb(49, 51, 124, 0.2)",
        pointHoverBorderColor: "rgb(49, 51, 124, 0.5)",
      },
      {
        label: "Incremento poblacional para el siguiente año",
        data: incrementos,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        pointBackgroundColor: "rgba(54, 162, 235, 1)",
        pointBorderColor: "rgba(54, 162, 235, 1)",
        pointBorderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(54, 162, 235, 1)",
        pointHoverBorderColor: "rgba(54, 162, 235, 1)",
      },
      {
        label: "Estimado Poblacional",
        data: estimado,
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
      },
    ],
  };

  const opcionesGrafico = {
    title: {
      display: true,
      text: "Proyección de cantidad de registros para el siguiente año",
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  ////Listar Notas Gestión Becarios
  const [dataNG, setDataNG] = useState([]);
  const [datosGraficoN, setDatosGrafico] = useState({});
  const [opcionesGraficoN, setOpcionesGrafico] = useState({});

  useEffect(() => {
    // Cargar datos desde un API o un archivo JSON
    fetch("https://bqef-bo.com/api/A/dashNotB.php")
      .then((res) => res.json())
      .then((data) => setDataNG(data));
  }, []);

  const [selectedUser, setSelectedUser] = useState("");

  const handleSelectUser = (e) => {
    setSelectedUser(e.target.value);
  };
  // const [selectedUser, setSelectedUser] = useState("");
  const [dataS, setDataS] = useState([]);

  useEffect(() => {
    setDataS(dataNG.filter((iteb) => iteb.id_bec === selectedUser));
  }, [selectedUser]);

  useEffect(() => {
    // Filtrar datos para el usuario seleccionado
    const filteredData = dataNG.filter((iteb) => iteb.id_bec === selectedUser);

    // Crear datos para el gráfico
    const data = {
      labels: filteredData.map((iteb) => iteb.anio), // Año en el eje X
      datasets: [
        {
          label: "Notas Gestión", // Etiqueta del conjunto de datos
          data: filteredData.map((iteb) => iteb.promedio_semestre), // Nota A1 en el eje Y
          borderColor: "rgb(49, 51, 124)", // Color de la línea
          backgroundColor: "rgba(217, 83, 79, 0.6)", //
          borderWidth: 2, // Ancho de la línea
        },
      ],
    };

    // Crear opciones para el gráfico
    const options = {
      scales: {
        y: {
          beginAtZero: true, // Comienza el eje Y en 0
        },
      },
    };

    setDatosGrafico(data);
    setOpcionesGrafico(options);
  }, [selectedUser, dataNG]);
  const uniqueUsers = {};
  dataNG.forEach((iteb) => {
    if (!uniqueUsers[iteb.nombre]) {
      uniqueUsers[iteb.nombre] = {
        id_bec: iteb.id_bec,
        apellido: iteb.apellido,
      };
    }
  });
  ////Abrir modal proyeccion
  const [alertVisible, setAlertVisible] = useState(true);
  const onDismiss = () => setAlertVisible(false);
  const abrirCerrarModalProyec = () => {
    // Aquí va el código para abrir el modalProy
    setModalProy(!modalProy);
  };
  ////Modal REPORTE NOTAS
  const [modalVerNot, setModalVerNot] = useState(false);
  const abrirCerrarModalVerNot = () => {
    setModalVerNot(!modalVerNot);
  };
  const toggle = () => setModalVerNot(!modalVerNot);
  const closeBtn2 = (
    <Button className="close" onClick={() => abrirCerrarModalVerNot()}>
      &times;
    </Button>
  );
  return (
    <div id="main_content">
      <div className="tra">
        <CardGroup>
          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}
          >
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Cuentas</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Becarios
                </CardSubtitle>
                <CardText>
                  {dataB.map((item) => (
                    <b key={item.id} style={{ color: "rgb(33 33 185)" }}>
                      {item.CBec}
                    </b>
                  ))}
                </CardText>
                <Button
                  className="btn-success"
                  onClick={() => abrirCerrarModalVerNot()}
                >
                  Ver
                </Button>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}
              >
                <FaIcons.FaUserGraduate />
              </CardBody>
            </div>
          </Card>

          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}
          >
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Cuentas</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Personal
                </CardSubtitle>
                <CardText>
                  {dataP.map((item) => (
                    <b key={item.id} style={{ color: "rgb(33 33 185)" }}>
                      {item.CPer}
                    </b>
                  ))}
                </CardText>
                <Button className="btn-success" href="./personal">
                  Ver
                </Button>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}
              >
                <FaIcons.FaUserTie />
              </CardBody>
            </div>
          </Card>
          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}
          >
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Informes</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Entregados
                </CardSubtitle>
                <CardText>
                  {dataI.map((item) => (
                    <b key={item.id_doc} style={{ color: "rgb(33 33 185)" }}>
                      {item.CInf}
                    </b>
                  ))}
                </CardText>
                <Button
                  className="btn-success"
                  onClick={() => abrirCerrarModalVerInf()}
                >
                  Ver
                </Button>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}
              >
                <FaIcons.FaReadme />
              </CardBody>
            </div>
          </Card>
          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}
          >
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Fecha Entrega</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Informes
                </CardSubtitle>
                <CardText>
                  {dataF.map((item) => (
                    <b key={item.id_fech} style={{ color: "rgb(33 33 185)" }}>
                      {item.fech_lit}
                    </b>
                  ))}
                </CardText>
                <Button disabled className="btn-success">
                  Ver
                </Button>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}
              >
                <FaIcons.FaRegCalendarCheck />
              </CardBody>
            </div>
          </Card>
        </CardGroup>
        {/* // */}
        <Row>
          <Col sm="4">
            <Card body>
              <CardTitle tag="h5">Histórico de registros:</CardTitle>
              <CardText
                className="text-center"
                style={{ color: "rgb(33 33 185)" }}
              >
                <b>Becarios</b>
              </CardText>
              <div style={{ width: "100%", height: "400px" }}>
                <Bar data={data} options={opciones} />
              </div>
            </Card>
          </Col>
          <Col sm="4">
            <Card body>
              <CardTitle tag="h5">Registro según región:</CardTitle>
              <CardText
                className="text-center"
                style={{ color: "rgb(33 33 185)" }}
              >
                <b>Becarios</b>
              </CardText>
              <div style={{ width: "100%", height: "400px" }}>
                <Doughnut data={dataPie} options={opcionesPie} />
              </div>
            </Card>
          </Col>
          <Col sm="4">
            <Card body>
              <CardTitle tag="h5">Actividades recientes:</CardTitle>
              <div className="table-responsive" style={{ height: "450px" }}>
                <Table id="tabl">
                  <thead id="stahead">
                    <tr className="text-center">
                      <th>#</th>
                      <th>Usuario</th>
                      <th>Detalle</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataL.map((usr, nu) => (
                      <tr className="text-center" key={usr.id_l}>
                        <td>{nu + 1}</td>
                        <td>{usr.usr_mod}</td>
                        <td>{usr.detalle}</td>
                        <td>{usr.fechaA}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Alert
        color="info"
        isOpen={alertVisible}
        toggle={onDismiss}
        style={{
          maxWidth: "80vw", // utiliza unidad relativa vw para ancho
          position: "absolute",
          top: "50%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          animation: "slideIn 0.8s forwards",
        }}
      >
        <span
          style={{
            cursor: "pointer",
            display: "flex", // Agregamos display: flex para alinear elementos
            alignItems: "center", // Centramos verticalmente los elementos
          }}
          onClick={() => abrirCerrarModalProyec()}
        >
          <FaIcons.FaChartLine />
          &nbsp; PROYECCIÓN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Alert>
      {/* Modal VER */}
      <Modal isOpen={modalVer} size="xl">
        <ModalHeader className="header_m" close={closeBtn}>
          Reporte Entrega Informes
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody className="p-0">
              <Row className="justify-content-center">
                <object
                  data={"https://bqef-bo.com/api/PDF/reporte_pdf_Informes.php"}
                  type="application/pdf"
                  width="400"
                  height="600"
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </object>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {/* Modal VER REPORTE INFORMES*/}
      <Modal isOpen={modalVerInf} size="xl">
        <ModalHeader className="header_mo">
          Estado Informes Entregados
        </ModalHeader>
        <div className="frm">
          <Link
            to={"https://bqef-bo.com/api/PDF/reporte_pdf_Informes.php"}
            target="_blank"
          >
            <Button color="primary" size="lg">
              <FaIcons.FaFileDownload /> Reporte
            </Button>
          </Link>
        </div>
        <ModalBody>
          <Table responsive="sm" className="frm">
            <thead>
              <tr className="text-center tra title-form">
                <th>N°</th>
                <th>Nombre Becario</th>
                <th>N. Documento</th>
                <th>Fecha E.</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {dataF.map((itemF) => (
                <>
                  <tr key={itemF.id_fech}></tr>
                  {dataFI.map((item, n1) => {
                    const fechaActual = new Date(itemF.fecha);
                    const fechaDocumento = new Date(item.f_cargado);
                    const fnull = new Date(0);

                    let estado = "";
                    let color = "";

                    if (
                      fechaDocumento.toISOString() === fechaActual.toISOString()
                    ) {
                      estado = "ENTREGADO";
                      color = "#2E8B57";
                    } else if (fechaDocumento > fechaActual) {
                      estado = "RETRASO";
                      color = "#CD5C5C";
                    } else if (
                      fechaDocumento.toISOString() === fnull.toISOString()
                    ) {
                      estado = "PENDIENTE";
                      color = "#db922e";
                    } else {
                      estado = "ENTREGADO";
                      color = "#2E8B57";
                    }

                    return (
                      <tr className="text-center" key={item.id_doc}>
                        <td>{n1 + 1}</td>
                        <td>{item.responsable}</td>
                        <td>{item.nom_doc}</td>
                        <td>{item.f_cargado}</td>
                        <td
                          align="center"
                          style={{ color: "white", backgroundColor: color }}
                        >
                          {estado}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalVerInf()}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal VER REPORTE CUENTAS BECARIOS*/}
      <Modal isOpen={modalVerNot} size="lg">
        <ModalHeader className="header_mo" toggle={toggle} close={closeBtn2}>
          Estado Académico Becarios
        </ModalHeader>
        <ModalBody>
          <Link
            to={"https://bqef-bo.com/api/PDF/reporte_pdf_Informes.php"}
            target="_blank"
          >
            <Button color="primary" size="lg">
              <FaIcons.FaFileDownload /> Reporte
            </Button>
          </Link>
          <hr></hr>
          <Input
            className="form-control"
            type="select"
            onChange={handleSelectUser}
          >
            <option value="">Seleccione becario</option>
            {Object.keys(uniqueUsers)
              .sort((a, b) => a.localeCompare(b))
              .map((nombre, index) => (
                <option key={index} value={uniqueUsers[nombre].id_bec}>
                  {index + 1}. {nombre} {uniqueUsers[nombre].apellido}
                </option>
              ))}
          </Input>
          <Table id="tabl">
            <thead id="stahead">
              <tr className="text-center">
                <th>N°</th>
                <th>Nombre Becario</th>
                <th>N. Académica</th>
                <th>Gestión</th>
                <th>Semestre</th>
                <th>N. Evaluación</th>
              </tr>
            </thead>
            <tbody>
              {dataS.map((iteb, nb) => (
                <tr key={iteb.id_bec + nb} className="text-center">
                  <td>{nb + 1}</td>
                  <td>
                    {iteb.nombre} {iteb.apellido}
                  </td>
                  <td>{iteb.promedio_semestre}</td>
                  <td>{iteb.anio}</td>
                  <td>{iteb.semestre}</td>
                  <td>{iteb.nota_eva}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Card body>
            <CardTitle tag="h6">Estadísticas</CardTitle>
            <CardText
              className="text-center"
              style={{ color: "rgb(33 33 185)" }}
            >
              <b>
                Becario: {selectedUser ? selectedUser : "Seleccione un becario"}
              </b>
            </CardText>
            <div style={{ width: "100%", height: "400px" }}>
              <Line data={datosGraficoN} options={opcionesGraficoN} />
            </div>
          </Card>
        </ModalBody>
      </Modal>

      {/* Modal proyección*/}
      <Modal isOpen={modalProy} size="lg">
        <ModalHeader className="header_mo">
          Proyección poblacional de becarios
        </ModalHeader>
        <ModalBody>
          <Card body>
            <CardText
              className="text-center"
              style={{ color: "rgb(33 33 185)" }}
            >
              <b>Becarios</b>
            </CardText>
            <div style={{ width: "100%", height: "400px" }}>
              <Line data={datosGrafico} options={opcionesGrafico} />
            </div>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalProy()}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Administrador;
