import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Protected(props) {
  const naviget = useNavigate();
  const { Component } = props;
  useEffect(() => {
    let login = localStorage.getItem("login");
    if (!login) {
      localStorage.setItem("loginStatus", mostrarAlertaIni());
      naviget("/", { replace: true });
    }
  }, [naviget]);
  ////ALERTAS
  const mostrarAlertaIni = () => {
    Swal.fire({
      showConfirmButton: false,
      title: "Error!",
      text: "Por favor inicie sesión!",
      timer: 5000,
      icon: "error",
    });
  };
  return <Component />;
}
