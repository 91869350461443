import React, { useState, useEffect } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Form,
  Container,
} from "reactstrap";
import { Table, Button } from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import "../Bec/bec.scss";
import Swal from "sweetalert2";

function ListUser() {
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    id: "",
    id_bec: "",
    nombre: "",
    apellido: "",
    email: "",
    contrasena_lit: "",
    celular: "",
  });
  // Definir un objeto de validación con patrones de expresiones regulares y estados de validación iniciales
  const validation = {
    nombre: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    apellido: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    id_bec: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    email: {
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      isValid: false,
    },
    contrasena_lit: {
      regex:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&%"*#¡¿])[A-Za-z\d@$!%?&%"*#¡¿]{8,}$/,
      isValid: true,
    },
    celular: { regex: /^\d{8,8}$/, isValid: true },
  };

  const [isValid, setIsValid] = useState({
    nombre: true,
    apellido: true,
    id_bec: true,
    email: false,
    celular: true,
    contrasena_lit: true,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuarioSeleccionado((prevState) => ({ ...prevState, [name]: value }));

    const isValidValue = validation[name].regex.test(value);
    setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };
  ////Datos usuario Administrador
  const [dataAdm, setDataAdm] = useState({});
  const peticionGetAdm = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/contraadm.php`, {
        params: {
          id: localStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        setDataAdm(response.data);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error al cargar los datos.",
          footer: `Código de error: ${error.response.status}`,
        });
      });
  };
  /////Listado usuarios becarios
  const baseUrl = "https://bqef-bo.com/api/A/cruB.php";
  const UrlUpd = "https://bqef-bo.com/api/A/cruUB.php";
  const [data, setData] = useState([]);
  async function peticionGet() {
    try {
      const res = await axios.get(baseUrl);
      setData(res.data);
      // console.log(res.data);
      setTablaUsuarios(res.data);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al cargar los datos.",
        footer: `Código de error: ${error.response.status}`,
      });
    }
  }
  /////Crear Usuario becario
  async function peticionPost(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("nombre", usuarioSeleccionado.nombre);
    fd.append("apellido", usuarioSeleccionado.apellido);
    fd.append("id_bec", usuarioSeleccionado.id_bec);
    fd.append("email", usuarioSeleccionado.email);
    fd.append("contrasena_lit", usuarioSeleccionado.contrasena_lit);
    fd.append("celular", usuarioSeleccionado.celular);
    fd.append("usu_creacion", dataAdm.id_adm);
    try {
      const res = await axios.post(baseUrl, fd);
      console.log(res.data);
      mostrarAlertaN();
      peticionGet();
      abrirCerrarModalInsertar();
    } catch (error) {
      console.log(error.response.data);
      mostrarAlertaError();
    }
  }
  /////Modificar Usuario becario
  const peticionPut = async () => {
    var f = new FormData();
    f.append("nombre", usuarioSeleccionado.nombre);
    f.append("apellido", usuarioSeleccionado.apellido);
    f.append("email", usuarioSeleccionado.email);
    f.append("contrasena_lit", usuarioSeleccionado.contrasena_lit);
    f.append("celular", usuarioSeleccionado.celular);
    f.append("usu_modificacion", dataAdm.id_adm);
    f.append("METHOD", "PUT");
    await axios
      .post(UrlUpd, f, { params: { id_bec: usuarioSeleccionado.id_bec } })
      .then((response) => {
        var dataNueva = data;
        dataNueva.map((Usuario) => {
          if (Usuario.id_bec === usuarioSeleccionado.id_bec) {
            Usuario.nombre = usuarioSeleccionado.nombre;
            Usuario.apellido = usuarioSeleccionado.apellido;
            Usuario.email = usuarioSeleccionado.email;
            Usuario.contrasena_lit = usuarioSeleccionado.contrasena_lit;
            Usuario.celular = usuarioSeleccionado.celular;
          }
        });
        setData(dataNueva);
        //console.log(response.data);;
        peticionGet();
        abrirCerrarModalEditar();
        mostrarAlertaU();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////Eliminar Usuario becario
  async function peticionDelete(id_bec) {
    const res = await axios.delete(`${baseUrl}?id_bec=${id_bec}`);
    abrirCerrarModalEliminar();
    peticionGet();
    mostrarAlertaD();
    console.log(res.data);
  }

  const seleccionarUsuario = (Usuario, caso) => {
    setUsuarioSeleccionado(Usuario);

    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  useEffect(() => {
    peticionGet();
    peticionGetAdm();
  }, []);
  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    // Convert the search term to lowercase for case-insensitive comparison
    const searchTermLower = terminoBusqueda.toLowerCase();

    // Use the filter() method to filter the tableUsers array based on the search term
    const resultadosBusqueda = tablaUsuarios.filter(
      (elemento) =>
        elemento.nombre.toLowerCase().includes(searchTermLower) ||
        elemento.apellido.toLowerCase().includes(searchTermLower) ||
        elemento.id_bec.toString().toLowerCase().includes(searchTermLower) ||
        elemento.celular.toString().toLowerCase().includes(searchTermLower) ||
        elemento.email.toLowerCase().includes(searchTermLower)
    );

    // Set the data to the filtered array
    setData(resultadosBusqueda);
  };
  ////ALERTAS
  const mostrarAlertaN = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Nuevo usuario registrado correctamente.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaU = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Usuario Modificado.",
      timer: 5000,
      icon: "info",
    });
  };
  const mostrarAlertaD = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Eliminado!",
      text: "Usuario Eliminado.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaError = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Error!",
      text: "No se pudo registrar.",
      timer: 5000,
      icon: "error",
    });
  };
  //////Filtrar columnas
  const [sortField, setSortField] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  const sortedData = data.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  ///
  const [theme, setTheme] = useState("light");

  const handleChangeTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };
  ////

  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Listado Becarios</h2>
        </div>
        <div className="frm">
          <Button
            color="success"
            size="lg"
            onClick={() => abrirCerrarModalInsertar()}
          >
            <FaIcons.FaPlus /> Añadir
          </Button>
        </div>

        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre, Apellido o Email"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead id="stahead">
            <tr className="text-center">
              <th>N°</th>
              <th>
                <span onClick={() => handleSortingChange("nombre")}>
                  Nombres
                  {sortField === "nombre" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nombre" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("apellido")}>
                  Apellidos
                  {sortField === "apellido" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "apellido" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("id_bec")}>
                  ID Usuario
                  {sortField === "id_bec" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "id_bec" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("email")}>
                  Correo
                  {sortField === "email" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "email" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("contrasena_lit")}>
                  Clave
                  {sortField === "contrasena_lit" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "contrasena_lit" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("celular")}>
                  Celular
                  {sortField === "celular" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "celular" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((Usuario, index) => (
              <tr className="text-center" key={Usuario.id}>
                <td>{index + 1}</td>
                <td>{Usuario.nombre}</td>
                <td>{Usuario.apellido}</td>
                <td>{Usuario.id_bec}</td>
                <td>{Usuario.email}</td>
                <td>{Usuario.contrasena_lit}</td>
                <td>{Usuario.celular}</td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => seleccionarUsuario(Usuario, "Editar")}
                  >
                    Editar
                  </button>{" "}
                  {"  "}
                  <button
                    className="btn btn-danger"
                    onClick={() => seleccionarUsuario(Usuario, "Eliminar")}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Modal añadir usuario */}
        <Modal isOpen={modalInsertar}>
          <ModalHeader className="header_mo">Insertar Usuario</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Nombres: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="nombre"
                  value={usuarioSeleccionado.nombre}
                  onChange={handleChange}
                />
                {!isValid.nombre && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Apellidos: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="apellido"
                  value={usuarioSeleccionado.apellido}
                  onChange={handleChange}
                />
                {!isValid.apellido && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Id Usuario: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="id_bec"
                  value={usuarioSeleccionado.id_bec}
                  onChange={handleChange}
                />{" "}
                {!isValid.id_bec && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <Label>Correo: </Label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={usuarioSeleccionado.email}
                onChange={handleChange}
              />
              {!isValid.email && (
                <span style={{ color: "red" }}>Correo no válido</span>
              )}
              <FormGroup>
                <Label>Clave: </Label>
                <Input
                  type="password"
                  className="form-control"
                  name="contrasena_lit"
                  value={usuarioSeleccionado.contrasena_lit}
                  onChange={handleChange}
                />
                {!isValid.contrasena_lit && (
                  <span style={{ color: "red" }}>
                    La contraseña debe tener al menos 8 caracteres, incluyendo
                    mayúsculas, minúsculas, números y caracteres especiales
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Celular: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="celular"
                  value={usuarioSeleccionado.celular}
                  onChange={handleChange}
                />
                {!isValid.celular && (
                  <span style={{ color: "red" }}>Número celular no válido</span>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={(e) => peticionPost(e)}
              disabled={
                !isValid.nombre ||
                !isValid.apellido ||
                !isValid.id_bec ||
                !isValid.email ||
                !isValid.celular ||
                !isValid.contrasena_lit
              }
            >
              Guardar
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalInsertar()}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modal Editar */}
        <Modal isOpen={modalEditar}>
          <ModalHeader className="header_mo">Modificar Usuario</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>
                  Nombres:{usuarioSeleccionado && usuarioSeleccionado.id_bec}
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="nombre"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.nombre}
                />
                {!isValid.nombre && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Apellidos: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="apellido"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.apellido}
                />
                {!isValid.apellido && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Correo: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.email}
                />
                {!isValid.email && (
                  <span style={{ color: "red" }}>Correo no válido</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Clave: </Label>
                <Input
                  type="password"
                  className="form-control"
                  name="contrasena_lit"
                  onChange={handleChange}
                  value={
                    usuarioSeleccionado && usuarioSeleccionado.contrasena_lit
                  }
                />
                {!isValid.contrasena_lit && (
                  <span style={{ color: "red" }}>
                    La contraseña debe tener al menos 8 caracteres, incluyendo
                    mayúsculas, minúsculas, números y caracteres especiales
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Celular: </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="celular"
                  onChange={handleChange}
                  value={usuarioSeleccionado && usuarioSeleccionado.celular}
                />
                {!isValid.celular && (
                  <span style={{ color: "red" }}>Número celular no válido</span>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={() => peticionPut()}
              disabled={
                !isValid.nombre ||
                !isValid.apellido ||
                !isValid.id_bec ||
                !isValid.email ||
                !isValid.celular ||
                !isValid.contrasena_lit
              }
            >
              Editar
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEditar()}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEliminar}>
          <ModalHeader className="header_mo">Eliminar comunicado</ModalHeader>
          <ModalBody>
            ¿Estás seguro que deseas eliminar el Usuario{" "}
            {usuarioSeleccionado && usuarioSeleccionado.nombre}?
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={() => peticionDelete(usuarioSeleccionado.id_bec)}
            >
              Sí
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEliminar()}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default ListUser;
