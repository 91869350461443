import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Form,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import "../bec.scss";
import axios from "axios";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";

function Nota2() {
  const [data, setData] = useState({
    id: "",
    id_B: "",

    mat_a1: "",
    nota_a1: "",

    mat_b1: "",
    nota_b1: "",

    mat_c1: "",
    nota_c1: "",

    mat_d1: "",
    nota_d1: "",

    mat_e1: "",
    nota_e1: "",

    mat_f1: "",
    nota_f1: "",

    mat_g1: "",
    nota_g1: "",

    semestre: "",
    anio: "",
    anio_carr: "",
  });
  const iduser = localStorage.getItem("iduser");
  // Definir un objeto de validación con patrones de expresiones regulares y estados de validación iniciales
  const validation = {
    nota_a1: { regex: /^(100|[0-9]|[1-9][0-9])$/, isValid: true },
    nota_b1: { regex: /^(100|[0-9]|[1-9][0-9])$/, isValid: true },
    nota_c1: { regex: /^(100|[0-9]|[1-9][0-9])$/, isValid: true },
    nota_d1: { regex: /^(100|[0-9]|[1-9][0-9])$/, isValid: true },
    nota_e1: { regex: /^(100|[0-9]|[1-9][0-9])$/, isValid: true },
    nota_f1: { regex: /^(100|[0-9]|[1-9][0-9])$/, isValid: true },
    nota_g1: { regex: /^(100|[0-9]|[1-9][0-9])$/, isValid: true },
  };

  const [isValid, setIsValid] = useState({
    nota_a1: true,
    nota_b1: true,
    nota_c1: true,
    nota_d1: true,
    nota_e1: true,
    nota_f1: true,
    nota_g1: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));

    const isValidValue = validation[name].regex.test(value);
    setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
  };
  ///Listar notas 2
  const peticionGet = async () => {
    await axios
      .get(`https://bqef-bo.com/api/B/nota2.php`, {
        params: {
          id: localStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        // // ////console.log(response.data);;
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Modificar notas 2
  const peticionPut = async () => {
    var f = new FormData();
    ///)

    f.append("nota_a1", data.nota_a1);

    f.append("nota_b1", data.nota_b1);

    f.append("nota_c1", data.nota_c1);

    f.append("nota_d1", data.nota_d1);

    f.append("nota_e1", data.nota_e1);

    f.append("nota_f1", data.nota_f1);

    f.append("nota_g1", data.nota_g1);

    ///
    f.append("METHOD", "PUT");
    await axios
      .post(`https://bqef-bo.com/api/B/nota2.php`, f, {
        params: { id: data.id_B },
      })
      .then((response) => {
        setData(response);
        peticionGet();
        mostrarAlertaU();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    peticionGet();
  }, []);
  ////ALERTAS
  const mostrarAlertaU = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Registro Actualizado.",
      timer: 5000,
      icon: "success",
    });
  };
  ///DROPDOWN
  const menuItem = [
    {
      path: "../nota1",
      name: "Primer Semestre",
    },
    {
      path: "../nota2",
      name: "Segundo Semestre",
    },
    {
      path: "../nota3",
      name: "Tercer Semestre",
    },
    {
      path: "../nota4",
      name: "Cuarto Semestre",
    },
    {
      path: "../nota5",
      name: "Quinto Semestre",
    },
    {
      path: "../nota6",
      name: "Sexto Semestre",
    },
    {
      path: "../nota7",
      name: "Séptimo Semestre",
    },
    {
      path: "../nota8",
      name: "Octavo Semestre",
    },
    {
      path: "../nota9",
      name: "Noveno Semestre",
    },
    {
      path: "../nota10",
      name: "Décimo Semestre",
    },
  ];
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Notas: {iduser}</h2>
        </div>
        <Form className="frm">
          <UncontrolledDropdown direction="down">
            <DropdownToggle caret color="danger">
              SEMESTRES
            </DropdownToggle>
            <DropdownMenu>
              {menuItem.map((item, index) => (
                <DropdownItem key={index}>
                  <NavLink to={item.path} exact activeClassName="active">
                    <div className="link_text">{item.name}</div>
                  </NavLink>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          <hr></hr>
          <Row className="text-center header_mo ">
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <h5>Materia</h5>
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <h5>Nota</h5>
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <h5>Semestre</h5>
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <h5>Año</h5>
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.mat_a1}
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <Input
                name="nota_a1"
                type="text"
                onChange={handleChange}
                value={data.nota_a1}
              />
              {!isValid.nota_a1 && (
                <span style={{ color: "red" }}>
                  Error formato, la nota debe estar entre 0-100
                </span>
              )}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.semestre}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.anio}
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.mat_b1}
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <Input
                name="nota_b1"
                type="text"
                onChange={handleChange}
                value={data.nota_b1}
              />
              {!isValid.nota_b1 && (
                <span style={{ color: "red" }}>
                  Error formato, la nota debe estar entre 0-100
                </span>
              )}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.semestre}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.anio}
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.mat_c1}
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <Input
                name="nota_c1"
                type="text"
                onChange={handleChange}
                value={data.nota_c1}
              />
              {!isValid.nota_c1 && (
                <span style={{ color: "red" }}>
                  Error formato, la nota debe estar entre 0-100
                </span>
              )}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.semestre}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.anio}
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.mat_d1}
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <Input
                name="nota_d1"
                type="text"
                onChange={handleChange}
                value={data.nota_d1}
              />
              {!isValid.nota_d1 && (
                <span style={{ color: "red" }}>
                  Error formato, la nota debe estar entre 0-100
                </span>
              )}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.semestre}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.anio}
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.mat_e1}
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <Input
                name="nota_e1"
                type="text"
                onChange={handleChange}
                value={data.nota_e1}
              />
              {!isValid.nota_e1 && (
                <span style={{ color: "red" }}>
                  Error formato, la nota debe estar entre 0-100
                </span>
              )}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.semestre}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.anio}
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.mat_f1}
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <Input
                name="nota_f1"
                type="text"
                onChange={handleChange}
                value={data.nota_f1}
              />
              {!isValid.nota_f1 && (
                <span style={{ color: "red" }}>
                  Error formato, la nota debe estar entre 0-100
                </span>
              )}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.semestre}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.anio}
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.mat_g1}
            </Col>
            <Col
              md={2}
              style={{
                border: "1.5px solid #1a5a41",
              }}
            >
              <Input
                name="nota_g1"
                type="text"
                onChange={handleChange}
                value={data.nota_g1}
              />
              {!isValid.nota_g1 && (
                <span style={{ color: "red" }}>
                  Error formato, la nota debe estar entre 0-100
                </span>
              )}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.semestre}
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                border: "1.5px solid #1a5a41",
              }}
            >
              {data.anio}
            </Col>
          </Row>
          <div id="fotb">
            <Button
              color="success"
              size="lg"
              onClick={() => peticionPut()}
              disabled={
                !isValid.nota_a1 ||
                !isValid.nota_b1 ||
                !isValid.nota_c1 ||
                !isValid.nota_d1 ||
                !isValid.nota_e1 ||
                !isValid.nota_f1 ||
                !isValid.nota_g1
              }
            >
              Modificar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Nota2;
